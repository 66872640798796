import * as firebase from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import { getFirestore } from "firebase/firestore";
import "@firebase/database";
import 'firebase/firestore';

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyC4EjUNeTpFxIShztsw6Hjh3om3K06WPoY",
    authDomain: "quickeat-1475967802201.firebaseapp.com",
    databaseURL: "https://quickeat-1475967802201.firebaseio.com",
    projectId: "quickeat-1475967802201",
    storageBucket: "quickeat-1475967802201.appspot.com",
    messagingSenderId: "798343171799",
    appId: "1:798343171799:web:27a527487754f45f092a92",
    measurementId: "G-3MR2YE7J8N"
}
const firebaseApp = firebase.initializeApp(firebaseConfig);

// const firestore = getFirestore()
// firestore.settings({ timestampsInSnapshots: true })

// utils
const db = getFirestore(firebaseApp)

// export utils/refs
export {
    db
}