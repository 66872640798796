import axios from 'axios'

export default {
    async infoUser () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/user`,
        })
    },
    async updateInfoUser (data) {
        return await axios({
            method: 'PUT',
            url: `${process.env.VUE_APP_API}/user`,
            data
        })
    },
    async deleteAccount () {
        return await axios({
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}/user`,
        })
    },
    async mailTokenLogin (token) {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/account/mail_token/${token}`
        })
    },

    async requestMailToken () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/account/mail_token/`,
        })
    },

    async login (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/signin/`,
            data
        })
    },
    async gmailLogin (data) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/account/signin/gmail/`,
            data
        })
    },
    async registerUser (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/signup`,
            data
        })
    },

    async sendMailPassword (data = {
        "email": ''
    }) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/password/`,
            data
        })
    },

    async resetPassword (data = {
        "token": '',
        "password": '',
        "password2": ''
    }) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/password/`,
            data
        })
    },

    async setPassword (data = {
        "old_password": '',
        "new_password": ''
    }) {
        return await axios({
            method: 'PUT',
            url: `${process.env.VUE_APP_API}/user/setpassword/`,
            data
        })
    },

    //ADDRESS
    async listAddress () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/user/address`,
        })
    },
    async addAddress (data) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/user/address`,
            data
        })
    },
    async deleteAddress (id) {
        return await axios({
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}/user/address/${id}`,
        })
    },
    async setAuthHeaders (accessToken) {
        if (accessToken != null) {
            axios.defaults.headers.common.Authorization = "token " + accessToken
        } else {
            axios.defaults.headers.common.Authorization = ''
        }
    },
    async listCards () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/card`,
        })
    },

}