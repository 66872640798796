<template>
  <MainHeader :routeName="routeName"/>
  <transition name="slide-fade">
    <ModalLoginRegister
        v-if="showModalLoginRegister"
        @close="closeModalLoginRegister"
    ></ModalLoginRegister>
  </transition>
  <router-view></router-view>
  <transition name="slide-fade">
    <ModalDelivery
        v-if="showModalDelivery"
        @close="showModalDelivery=false"
    ></ModalDelivery>
  </transition>
  <transition name="slide-fade">
    <ModalPreDelivery
        v-if="showModalPreDelivery"
        @close="showModalPreDelivery=false"
        @addAddress="showModalPreDelivery=false;showModalDelivery=true">
    </ModalPreDelivery>
  </transition>
  <FooterComponent></FooterComponent>
</template>

<script>

import {mapMutations, mapState} from "vuex"
import router from './router'
import OrderServices from "../src/services/order-services"
import User from "../src/services/user-services"
import { defineAsyncComponent } from 'vue';
import { db } from "./firebase"
import {doc, onSnapshot} from "firebase/firestore";


export default {
  components: {
    ModalDelivery: defineAsyncComponent(() => import( "@/components/MODALS/ModalDelivery.vue")),
    ModalPreDelivery: defineAsyncComponent(()=>import("./components/MODALS/ModalPreDelivery.vue")),
    MainHeader: defineAsyncComponent(() => import("./components/MainHeader")),
    ModalLoginRegister: defineAsyncComponent(() => import("@/components/MODALS/ModalLoginRegister")),
    FooterComponent: defineAsyncComponent(() => import('@/components/FooterComponent'))
  },
  data () {
    return {
      firestore_object: null,
      initialListen: true,
      localId: '',
      order_id: '',
      showModalBusinessBanner: false,
      showModalUserSmsValidation: false,
      showModalPreDelivery:false,
      showModalDelivery: false,
      routeName: '',
    }
  },
  watch: {
    goToPayClicked: function () {
      if (this.goToPayClicked) {
        this.showModalPreDelivery = true
        this.setGoToPayClicked(false)
      }
    },
    isAuthenticated: async function () {

      if (this.isAuthenticated) {
        await User.setAuthHeaders(this.user.token)
        await this.getShoppingCart()

        return
      }

      if (this.firestore_object !== null) {
        this.initialListen = true
        this.firestore_object()
      }
      await User.setAuthHeaders(null)
      await this.getShoppingCart()
    },
    $route: async function () {
      this.routeName = this.$route.name
      if (this.$route.name === 'pedidos') {
        if (this.firestore_object !== null) {
          this.firestore_object()
        }
      }
    }
  },
  async mounted() {
    await this.getShoppingCart()
  },
  async created() {
    let metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", getComputedStyle(document.body).getPropertyValue('--primary'));//setea el theme color desde una variable css
    if (this.isAuthenticated) {
      await User.setAuthHeaders(this.user.token)
    } else {
      await User.setAuthHeaders(null)
    }

    if ((this.webVersion < parseInt(process.env.VUE_APP_CURRENT_VERSION)) && this.webVersion !== 0) {
      this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
      window.location.reload()
    }

  },
  computed: {
    ...mapState(['showModalLoginRegister', 'isAuthenticated', 'user', 'goToPayClicked', 'typeOrder', 'isSelfService']),
  },
  methods: {
    ...mapMutations(['setGoToPayClicked', 'setShowModalLoginRegister', 'setListLocals', 'setShoppingCartInfo',
      'setAuthenticatedFalse', 'setWebVersion', 'clearProducts']),

    async getShoppingCart() {
      if (this.isAuthenticated) {
        try {

          let response = await OrderServices.getCurrentShoppingCart()
          if (response.data.success) {
            this.setShoppingCartInfo(response.data.data)
          } else {
            this.$toast.error(response.data.message)
          }
        } catch (e) {
          if (e.toString().includes('401')) {
            this.$toast.error(this.$t('toast.txt_sesion_expirada_por_favor'))
            this.setAuthenticatedFalse()
            this.clearProducts()
            await User.setAuthHeaders(null)
            await localStorage.clear()
            this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
            await this.$router.push("/")
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          }
          console.log("error", e)
        }
      } else {
        this.setShoppingCartInfo([])
      }
    },
    closeModalLoginRegister() {
      this.setShowModalLoginRegister(false)
    },
    fireStoreListenner() {

      this.firestore_object = onSnapshot(doc(db, `cenfotur__invoice/${this.order_id}`),
          {includeMetadataChanges: true},
          (doc) => {
            if (this.initialListen) {
              this.initialListen = false
              console.log("hizo initial listen")
            } else {
              if (doc.data() !== undefined) {

                let response = doc.data().status
                switch (response) {
                  case '0':
                    response = 'Tu pedido está pendiente'
                    break
                  case '1':
                    response = 'Tu pedido se está cocinando'
                    break

                  case '2':
                    response = 'Tu pedido está listo para recojo'
                    break
                  case '3':
                    response = 'Tu pedido fue anulado'
                    break
                  case '4':
                    response = 'Tu pedido fue entregado con éxito. ¡Buen provecho!'
                    break
                  case '5':
                    response = 'Mesa reservada con éxito'
                    break
                  case '6':
                    response = 'Tu pedido está en camino'
                    break
                }

                const notification = {
                  title: 'Quick eat!',
                  options: {
                    body: response,
                    requireInteraction: true,
                    icon: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png',
                    image: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png',
                    badge: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png'
                  },
                  events: {
                    onerror: function () {
                      //console.log('Error en la notif');
                    },
                    onclick: function () {
                      router.push('/perfil/pedidos');
                    },
                    onclose: function () {
                    },
                    onshow: function () {
                    }
                  }
                }

                this.$notification.show(notification.title, notification.options, notification.events)

                this.$toast.success(response)
              }
            }
          })
    }
  }
}
</script>
<style scoped lang="scss">
</style>